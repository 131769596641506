import Vue from "vue";

export function createOverlayVue(component, props, parent) {
  const rootEl = document.createElement("div");
  document.body.appendChild(rootEl);
  const modal = new Vue({
    parent,
    render(h) {
      return h(component, {
        ref: "component",
        props: props,
        on: {
          close() {
            modal.$emit("close", ...arguments);
            modal.$nextTick(() => {
              modal.$destroy();
              if (modal.$el && modal.$el.parentNode) {
                modal.$el.parentNode.removeChild(modal.$el);
              }
            });
          },
        },
      });
    },
  });
  modal.$mount(rootEl);
  return modal;
}

export function defaultOverlayModal(component, props = {}, callingComponent) {
  return new Promise((resolve, reject) => {
    function mount(resolvedComponent) {
      const m = createOverlayVue(resolvedComponent, props, callingComponent);
      m.$on("close", (modalResult) => {
        resolve(modalResult);
      });
    }
    if (component instanceof Promise) {
      component
        .then(function (loadedModule) {
          mount(loadedModule.default);
        })
        .catch(function (e) {
          reject(e);
        });
    } else {
      mount(component);
    }
  });
}
