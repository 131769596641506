import { toDate } from "~/src/util/date";

export const RELATION_IS = {
  PARENT: "Erziehungsberechtigte*r",
  CHILD: "Kind",
  MISC: "Sonstige",
};

export const PERSON_GENDER_FULL = {
  FEMALE: "weiblich",
  MALE: "männlich",
  NON_BINARY: "divers",
  UNKNOWN: "unbekannt",
};
export const PERSON_GENDER_ABBREV = {
  FEMALE: "w",
  MALE: "m",
  NON_BINARY: "d",
  UNKNOWN: "?",
};

export function getLastNames(family) {
  const lastNames = new Set();
  family.people.forEach(function (rel) {
    lastNames.add(rel.lastName);
  });
  return Array.from(lastNames.values());
}

export function getSerializedAddress(person) {
  return `${person.street}|${person.streetNumber}|${person.zip}|${person.locality}|${person.cityDistrictNumber}`;
}

export function getAddresses(family) {
  const addressStrings = new Set();
  const addresses = [];
  family.people.forEach(function (rel) {
    const addressString = getSerializedAddress(rel);
    if (!addressStrings.has(addressString)) {
      addressStrings.add(addressString);
      addresses.push({
        street: rel.street,
        streetNumber: rel.streetNumber,
        zip: rel.zip,
        locality: rel.locality,
        cityDistrictNumber: rel.cityDistrictNumber,
      });
    }
  });
  return addresses;
}

export function getEmptyFamilyRelation(overwrites = {}) {
  return {
    familyId: null,
    firstName: "",
    lastName: "",
    birthday: null,
    street: "",
    streetNumber: "",
    zip: "",
    locality: "",
    cityDistrictNumber: "",
    handicapped: 0,
    retired: 0,
    is: null,
    gender: null,
    citizenship: null,
    phone: [],
    ...overwrites,
  };
}

export function getFamilyRelationModel(familyId, person) {
  return {
    familyId,
    firstName: person.firstName,
    lastName: person.lastName,
    birthday: toDate(person.birthday),
    street: person.street,
    streetNumber: person.streetNumber,
    zip: person.zip,
    locality: person.locality,
    cityDistrictNumber: person.cityDistrictNumber,
    handicapped: person.handicapped ? 1 : 0,
    retired: person.retired ? 1 : 0,
    is: person.is,
    gender: person.gender,
    citizenship: person.citizenship,
    phone: person.phone || [],
  };
}

export function canGetAnyPerk(person) {
  return person.currentPassport && person.currentPassport.status === "VALID";
}

export function canGetDigitalPassport(person) {
  return person.currentPassport && person.currentPassport.status === "VALID";
}
