import Vue from "vue";

export function hasMouseIntractableParent(el, maxDepth = 6) {
  return getMouseIntractableParent(el, maxDepth) !== null;
}

export function getMouseIntractableParent(el, maxDepth = 6) {
  let e = el;
  let depth = 0;
  while (e && depth < maxDepth) {
    // a (with a href) button, input, textarea are the standard elements that are mouse interactabel
    if (
      ["A", "BUTTON", "INPUT", "TEXTAREA"].indexOf(e.tagName) > -1 &&
      (e.tagName !== "A" || e.href)
    ) {
      return e;
    }
    e = e.parentNode;
    depth += 1;
  }
  return null;
}

function parents(_node, ps) {
  if (_node.parentNode === null) {
    return ps;
  }
  return parents(_node.parentNode, ps.concat([_node]));
}

export const getScrollParent = (node) => {
  const regex = /(auto|scroll)/;

  const style = (_node, prop) =>
    getComputedStyle(_node, null).getPropertyValue(prop);
  const overflow = (_node) =>
    style(_node, "overflow") +
    style(_node, "overflow-y") +
    style(_node, "overflow-x");
  const scroll = (_node) => regex.test(overflow(_node));

  /* eslint-disable consistent-return */
  const scrollParent = (_node) => {
    if (!(_node instanceof HTMLElement || _node instanceof SVGElement)) {
      return;
    }

    const ps = parents(_node.parentNode, []);

    for (let i = 0; i < ps.length; i += 1) {
      if (scroll(ps[i])) {
        return ps[i];
      }
    }

    return document.scrollingElement || document.documentElement;
  };

  return scrollParent(node);
  /* eslint-enable consistent-return */
};

export function matches(node, selector) {
  if (node.msMatchesSelector) {
    return node.msMatchesSelector(selector);
  }
  return node.matches(selector);
}

export function getMatchingParent(node, selector) {
  const ps = parents(node, []);
  for (let i = 0; i < ps.length; i++) {
    if (matches(ps[i], selector)) {
      return ps[i];
    }
  }
  return null;
}

export const ReactiveViewport = new Vue({
  data() {
    return {
      height: 0,
      width: 0,
    };
  },
  created() {
    this.updateViewport();
    window.addEventListener("resize", this.updateViewport);
    window.addEventListener("orientationchange", this.updateViewport);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateViewport);
    window.removeEventListener("orientationchange", this.updateViewport);
  },
  methods: {
    updateViewport() {
      this.height = window.innerHeight;
      this.width = window.innerWidth;
    },
  },
});
