import Vue from "vue";
import { install } from "vue-demi";
import VueApollo from "vue-apollo";
import VueRouter from "vue-router";
import Element from "element-ui";
import locale from "element-ui/lib/locale/lang/de";
import VueMeta from "vue-meta";
import "~stylesheets/element-ui.scss";
import "~stylesheets/base-styles.scss";
import "./silence-async-validator";
import { URLS } from "./rails.js.erb";

install(Vue);

export function configureVue() {
  if (!Vue.appConfigDone) {
    // Vue.use(CompositionAPI);
    Vue.use(VueApollo);
    Vue.use(VueRouter);
    Vue.use(Element, { locale });
    Vue.use(VueMeta, {
      refreshOnceOnNavigation: true,
      keyName: "head",
    });

    Vue.prototype.$urls = URLS;
    Vue.prototype.$filters = {
      number2Human(number) {
        return (+number).toLocaleString();
      },
    };

    Vue.prototype.$apolloErrorHandle = function (error) {
      Element.MessageBox.alert(error, "Fehler");
    };

    Vue.prototype.$notReady = function (
      msg = "Diese Funktion ist noch nicht fertig"
    ) {
      Element.MessageBox.alert(msg, "Noch nicht fertig");
    };

    Vue.appConfigDone = true;
  }
}
