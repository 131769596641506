<template>
  <div>
    <input :id="`trix-input-${id}`" :value="value" type="hidden" />
    <editor-toolbar
      :id="id"
      :lang="lang"
      has-strike
      has-link
      has-code
      has-heading
      has-quote
      has-uploads
    />
    <trix-editor
      ref="trix"
      :class="customClass"
      :input="`trix-input-${id}`"
      :placeholder="placeholder"
      :toolbar="`trix-toolbar-${id}`"
      class="trix-content"
      @trix-attachment-add="emitAttachmentAdd"
      @trix-attachment-remove="emitAttachmentRemove"
      @trix-before-initialize="emitBeforeInitialize"
      @trix-blur="processTrixBlur"
      @trix-change="handleContentChange"
      @trix-file-accept="emitFileAccept"
      @trix-focus="processTrixFocus"
      @trix-initialize="handleInitialize"
      @trix-selection-change="emitSelectionChange"
    />
  </div>
</template>

<script>
import "trix";
import EditorToolbar from "~/src/components/common/richtext/EditorToolbar";
import BaseEditor from "~/src/components/common/richtext/BaseEditor";

export default {
  name: "CommentEditor",
  components: { EditorToolbar },
  mixins: [BaseEditor()],
};
</script>