<template>
  <summary-chrome :size="size" :to="to" thing="Familie" icon="ka-icon-users">
    <span slot="meta">{{ family.identifier }}</span>
    <span v-if="name" slot="name">{{ name }}</span>
    <em v-else slot="name">Leer</em>
    <slot slot="actions">
      <slot />
    </slot>
  </summary-chrome>
</template>

<script>
import SummaryChrome from "~/src/components/common/utils/SummaryChrome";
import SummaryMixin from "~/src/components/mixins/SummaryMixin";

export default {
  name: "FamilySummary",
  components: { SummaryChrome },
  mixins: [SummaryMixin],
  props: {
    family: {
      type: Object,
      required: true,
    },
  },
  computed: {
    name() {
      const { family } = this;
      const names = new Set();
      family.people.forEach(function (rel) {
        names.add(rel.lastName);
      });
      return Array.from(names.values()).join(", ");
    },
  },
};
</script>