export default {
  name: "SummaryMixin",
  props: {
    size: {
      type: String,
      default: "large",
    },
    to: {
      type: Object,
      default: null,
    },
  },
};
