<template>
  <summary-chrome :size="size" :to="to" :thing="thing" :icon="icon">
    <span slot="meta"><locale-date-time :date="document.createdAt" /></span>
    <span slot="name">{{ document.name }}</span>
    <slot slot="actions">
      <slot />
    </slot>
  </summary-chrome>
</template>

<script>
import SummaryChrome from "~/src/components/common/utils/SummaryChrome";
import SummaryMixin from "~/src/components/mixins/SummaryMixin";
import LocaleDateTime from "~/src/components/common/i18n/LocaleDateTime";

export default {
  name: "DocumentSummary",
  components: { LocaleDateTime, SummaryChrome },
  mixins: [SummaryMixin],
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  computed: {
    thing() {
      const { document } = this;
      if (document && document.file) {
        switch (document.file.contentType) {
          case "application/pdf":
            return "PDF";
          case "application/msexcel":
          case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          case "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
          case "application/vnd.oasis.opendocument.spreadsheet":
            return "Excel";
        }
        if (document.file.contentType.indexOf("image/") === 0) {
          return "Bild";
        }
        return document.file.contentType;
      }
      return "";
    },
    icon() {
      const { document } = this;
      if (document && document.file) {
        switch (document.file.contentType) {
          case "application/pdf":
            return "ka-icon-file-pdf";
          case "application/msexcel":
          case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          case "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
          case "application/vnd.oasis.opendocument.spreadsheet":
            return "ka-icon-file-excel";
        }
        if (document.file.contentType.indexOf("image/") === 0) {
          return "ka-icon-file-image";
        }
      }
      return null;
    },
  },
};
</script>
