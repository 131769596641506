var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{class:[
    _vm.$style.wrap,
    {
      [_vm.$style.center]: _vm.center,
      [_vm.$style.right]: _vm.right,
      [_vm.$style.stacked]: _vm.stacked,
      [_vm.$style.margin]: _vm.margin,
      [_vm.$style.primaryActions]: _vm.primaryActions,
    },
  ]},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }