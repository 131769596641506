<template functional>
  <el-tag
    :type="
      props.status === 'UNCONFIRMED' || props.status === 'DISABLED'
        ? 'danger'
        : props.status === 'LOCKED' || props.status === 'INVITED'
        ? 'warning'
        : 'success'
    "
    :size="props.size"
  >
    <template v-if="props.status === 'UNCONFIRMED'">
      E-Mail nicht bestätigt
    </template>
    <template v-else-if="props.status === 'INVITED'">
      Eingeladen
    </template>
    <template v-else-if="props.status === 'LOCKED'">
      Auto-Gesperrt
    </template>
    <template v-else-if="props.status === 'DISABLED'">
      Gesperrt
    </template>
    <template v-else-if="props.status === 'ACTIVE'">
      Aktiv
    </template>
    <template v-else-if="props.status === 'PLACEHOLDER'">
      Platzhalter
    </template>
    <template v-else>
      {{ props.status }}
    </template>
  </el-tag>
</template>

<script>
export default {
  name: "UserStatus",
  props: {
    status: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "mini",
    },
  },
};
</script>
