<template>
  <div :class="[$style.wrap, { [$style.inactive]: inactive }]">
    <div :class="$style.who">
      <el-avatar icon="el-icon-user-solid" size="small" />
      <span :class="$style.author">
        <a v-if="!userIsPlaceholder" :href="`mailto:${comment.user.email}`">{{
          comment.user.name
        }}</a>
        <em v-else>{{ comment.user.name }}</em>
        sagt <relative-date-time :date="comment.createdAt" />:
      </span>
      <el-popconfirm
        v-if="removable"
        :class="$style.remove"
        title="Kommentar wirklich löschen?"
        icon="el-icon-warning"
        icon-color="red"
        confirm-button-text="Ja, löschen"
        confirm-button-type="danger"
        cancel-button-text="Nein, doch nicht löschen"
        @confirm="$emit('remove')"
      >
        <el-button
          slot="reference"
          icon="el-icon-delete"
          size="mini"
          type="danger"
          plain
          circle
        />
      </el-popconfirm>
    </div>
    <app-panel :class="$style.body" v-html="comment.body" />
  </div>
</template>

<script>
import AppPanel from "~/src/components/common/layout/AppPanel";
import RelativeDateTime from "~/src/components/common/i18n/RelativeDateTime";

export default {
  name: "CommentDisplay",
  components: { RelativeDateTime, AppPanel },
  props: {
    comment: {
      type: Object,
      required: true,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    inactive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    userIsPlaceholder() {
      const { user } = this.comment;
      return user.email.indexOf("@placeholder.stja.de") > -1;
    },
  },
};
</script>

<style module lang="module.css">
.wrap + .wrap {
  margin-top: 1rem;
}
.inactive .body {
  pointer-events: none;
}
.who {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  line-height: 20px;
}
.author {
  margin-left: 8px;
  flex: 1;
}
.remove {
  margin-left: 8px;
}
</style>
