import { URLS } from "~/src/util/rails.js.erb";
import { GraphQLDirectUpload } from "~/src/util/upload";

export class AttachmentUpload {
  constructor(attachment, element, apollo) {
    this.attachment = attachment;
    this.element = element;
    this.directUpload = new GraphQLDirectUpload(attachment.file, apollo, this);
  }

  start() {
    this.directUpload.create(this.directUploadDidComplete.bind(this));
  }

  directUploadWillStoreFileWithXHR(xhr) {
    xhr.upload.addEventListener("progress", (event) => {
      const progress = (event.loaded / event.total) * 100;
      this.attachment.setUploadProgress(progress);
    });
  }

  directUploadDidComplete(error, attributes) {
    if (error) {
      throw new Error(`Direct upload failed: ${error}`);
    }

    this.attachment.setAttributes({
      sgid: attributes.attachableSgid,
      url: this.createBlobUrl(attributes.signedId, attributes.filename),
    });
  }

  createBlobUrl(signedId, filename) {
    return URLS.rails_service_blob
      .replace(":signed_id", signedId)
      .replace(":filename", encodeURIComponent(filename));
  }
}
