<script>
export default {
  name: "Nl2Br",
  functional: true,
  props: {
    value: {
      type: String,
      default: "",
    },
    emptyText: {
      type: String,
      default: null,
    },
  },
  render(createElement, context) {
    const { value, emptyText } = context.props;
    if (!value && emptyText) {
      return createElement("em", emptyText);
    }
    const lines = (value || "").split("\n");
    const linesWithBr = [];
    lines.forEach(function (line, i) {
      linesWithBr.push(line);
      if (i < lines.length - 1) {
        linesWithBr.push(createElement("br"));
      }
    });
    return createElement("span", linesWithBr);
  },
};
</script>
