// conditionally load polyfills – newer browsers should not be penalized!

const loadingQueue = [];

if (!window.Intl) {
  loadingQueue.push(import(/* webpackChunkName: "polyfills" */ "intl"));
  loadingQueue.push(
    import(/* webpackChunkName: "polyfills" */ "intl/locale-data/jsonp/en")
  );
  loadingQueue.push(
    import(/* webpackChunkName: "polyfills" */ "intl/locale-data/jsonp/de")
  );
}

if (!window.WeakMap) {
  loadingQueue.push(
    import(/* webpackChunkName: "polyfills" */ "weakmap-polyfill")
  );
}

if (!String.prototype.repeat) {
  loadingQueue.push(
    import(/* webpackChunkName: "polyfills" */ "~/src/polyfills/string-repeat")
  );
}

export function polyfilled(callback) {
  if (loadingQueue.length > 0) {
    Promise.all.call(Promise, loadingQueue).then(function () {
      callback();
    });
  } else {
    Promise.resolve().then(function () {
      callback();
    });
  }
}
