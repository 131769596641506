<template>
  <div :class="[$style.wrap, $style['size_' + size]]">
    <div :class="$style.kicker">
      <span :class="$style.thing">{{ thing }}</span>
      <span v-if="$slots.meta" :class="$style.meta"><slot name="meta" /></span>
    </div>
    <div :class="$style.summary">
      <slot name="icon">
        <i :class="icon" />
      </slot>
      <el-tooltip v-if="to" :content="jumpHint" :tabindex="-1">
        <router-link :to="to" :class="$style.name">
          <slot name="name" />
        </router-link>
      </el-tooltip>
      <template v-else>
        <span :class="$style.name">
          <slot name="name" />
        </span>
      </template>
      <span v-if="$slots.actions" :class="$style.actions">
        <slot name="actions" />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SummaryChrome",
  props: {
    size: {
      type: String,
      default: "large",
    },
    icon: {
      type: String,
      default: "el-icon-user",
    },
    thing: {
      type: String,
      required: true,
    },
    to: {
      type: Object,
      default: null,
    },
  },
  computed: {
    jumpHint() {
      const { thing } = this;
      switch (thing) {
        case "Familie":
        case "Person":
          return `Zur ${thing} springen`;
        default:
          return `Zum ${thing} springen`;
      }
    },
  },
};
</script>

<style module lang="module.css">
.wrap {
  line-height: 1.25;
  min-width: 200px;
  max-width: 100%;
}
.kicker {
  margin-bottom: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.summary {
  font-weight: bold;
  white-space: nowrap;
  width: 100%;
  display: inline-flex;
}
.thing {
  font-weight: bold;
}
.meta {

}
.summary > i, .summary > svg {
  flex: 0 0 auto;
  white-space: nowrap;
  margin-right: 6px;
}
.name {
  flex: 0 1 auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.actions {
  flex: 1 0 auto;
  white-space: nowrap;
  margin-left: 6px;
}
.actions > :global(.el-button) {
  vertical-align: top;
}

.size_large .kicker {
  font-size: 14px;
}
.size_large .summary {
  font-size: 20px;
}
.size_large .summary svg {
  height: 24px
}
.size_small .kicker {
  font-size: 12px;
}
.size_small .summary {
  font-size: 14px;
}
.size_small .summary svg {
  height: 18px
}
</style>
