import lang from "~/src/components/common/richtext/lang";
import { AttachmentUpload } from "~/src/components/common/richtext/upload";

let id = 0;

export default function (config = {}) {
  return {
    name: "BaseEditor",
    props: {
      value: {
        type: String,
        default: "",
      },
      placeholder: {
        type: String,
        default: "Hier tippen",
      },
      autofocus: {
        type: Boolean,
        default: false,
      },
      customClass: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        id: ++id,
        isActive: null,
        editorContent: this.value,
      };
    },
    computed: {
      lang() {
        return lang;
      },
    },
    watch: {
      value: "handleInitialContentChange",
    },
    methods: {
      reloadEditorContent(newContent) {
        // Reload HTML content
        this.$refs.trix.editor.loadHTML(newContent);
        // Move cursor to end of new content updated
        this.$refs.trix.editor.setSelectedRange(this.getContentEndPosition());
      },
      getContentEndPosition() {
        return this.$refs.trix.editor.getDocument().toString().length - 1;
      },
      handleContentChange(event) {
        this.editorContent = event.srcElement
          ? event.srcElement.value
          : event.target.value;
        this.$emit("input", this.editorContent);
        this.$emit("update:value", this.editorContent);
      },
      handleInitialize(event) {
        // console.log("handleInitialize", event);
        /*
         * If autofocus is true, manually set focus to
         * beginning of content (consistent with Trix behavior)
         */
        if (this.autofocus) {
          this.$refs.trix.editor.setSelectedRange(0);
        }
        this.$emit("trix-initialize", this.emitInitialize);
      },
      handleInitialContentChange(newContent, oldContent) {
        // console.log("handleInitialContentChange", event);
        newContent = newContent === undefined ? "" : newContent;
        if (
          this.$refs.trix.editor &&
          this.$refs.trix.editor.innerHTML !== newContent
        ) {
          /* Update editor's content when initial content changed */
          this.editorContent = newContent;
          /*
           *  If user are typing, then don't reload the editor,
           *  hence keep cursor's position after typing.
           */
          if (!this.isActive) {
            this.reloadEditorContent(this.editorContent);
          }
        }
      },
      emitAttachmentAdd(event) {
        const { attachment, target } = event;

        if (attachment.file) {
          const upload = new AttachmentUpload(attachment, target, this.$apollo);
          upload.start();
        }
        this.$emit("trix-attachment-add", event);
      },
      emitAttachmentRemove(event) {
        this.$emit("trix-attachment-remove", event);
      },
      emitBeforeInitialize(event) {
        // console.log("emitBeforeInitialize", event);
        this.$emit("trix-before-initialize", this.$refs.trix.editor, event);
      },
      emitFileAccept(event) {
        this.$emit("trix-file-accept", event);
      },
      disallowFile(event) {
        event.preventDefault();
      },
      emitInitialize(event) {
        // console.log("emitInitialize", event);
        this.$emit("trix-initialize", this.$refs.trix.editor, event);
      },
      emitSelectionChange(event) {
        // console.log("emitSelectionChange", event);
        this.$emit("trix-selection-change", this.$refs.trix.editor, event);
      },
      processTrixFocus(event) {
        // console.log("processTrixFocus", event);
        if (this.$refs.trix) {
          this.isActive = true;
          this.$emit("trix-focus", this.$refs.trix.editor, event);
        }
      },
      processTrixBlur(event) {
        // console.log("processTrixBlur", event);
        this.isActive = false;
        if (this.$refs.trix) {
          this.$emit("trix-blur", this.$refs.trix.editor, event);
        }
      },
      appendHtml(html) {
        this.$refs.trix.editor.recordUndoEntry("Insert HTML");
        this.$refs.trix.editor.setSelectedRange([1000000000]);
        this.$refs.trix.editor.insertHTML(html);
        this.$refs.trix.editor.setSelectedRange([1000000000]);
      },
    },
  };
}
