<template>
  <summary-chrome :size="size" :to="to" :thing="thing" :icon="icon">
    <span slot="meta">
      <passport-info :passport="passport" />
    </span>
    <span slot="name">{{ passport.passportNumber }}</span>
    <slot slot="actions">
      <slot />
    </slot>
  </summary-chrome>
</template>

<script>
import SummaryChrome from "~/src/components/common/utils/SummaryChrome";
import SummaryMixin from "~/src/components/mixins/SummaryMixin";
import { getPassportIcon, getShortPassType } from "~/src/util/passport";
import PassportInfo from "~/src/components/passports/PassportInfo";

export default {
  name: "PassportSummary",
  components: { PassportInfo, SummaryChrome },
  mixins: [SummaryMixin],
  props: {
    passport: {
      type: Object,
      required: true,
    },
  },
  computed: {
    thing() {
      const { passport } = this;
      if (passport) {
        return getShortPassType(passport.passportType);
      }
      return "";
    },
    icon() {
      const { passport } = this;
      if (passport) {
        return getPassportIcon(passport);
      }
      return null;
    },
  },
};
</script>
