import Vue from "vue";
import { groupBy } from "lodash/collection";
import { getISOWeek, getISOWeekYear } from "date-fns";

function z(number) {
  if (number < 10) {
    return "0" + number;
  }
  return number.toString();
}

export function getISO8601Date(date) {
  const d = toDate(date);
  return `${d.getFullYear()}-${z(d.getMonth() + 1)}-${z(d.getDate())}`;
}

export function getISO8601DateMonth(date) {
  const d = toDate(date);
  return `${d.getFullYear()}-${z(d.getMonth() + 1)}`;
}

export function iso8601DateToLocale(iso8601) {
  return toDate(iso8601).toLocaleDateString();
}

export function iso8601DateTimeToLocale(iso8601) {
  return toDate(iso8601).toLocaleString();
}
export function toDate(value) {
  if (value instanceof Date) {
    return value;
  }
  return new Date(value);
}

export function getSchoolYear(date) {
  const at = date instanceof Date ? date : new Date(date);
  const month = at.getMonth() + 1; // it's crazily zero-based
  const year = at.getFullYear();
  const sy = month >= 8 ? `${year}/${year + 1}` : `${year - 1}/${year}`;

  return `${sy}`;
}

export function getSchoolHalfYear(date) {
  const at = date instanceof Date ? date : new Date(date);
  const month = at.getMonth() + 1; // it's crazily zero-based
  const year = at.getFullYear();
  const sy = month >= 8 ? `${year}/${year + 1}` : `${year - 1}/${year}`;
  const fs = [8, 9, 10, 11, 12, 1].includes(month)
    ? "1. Halbjahr"
    : "2. Halbjahr";
  return `${sy} ${fs}`;
}

export function groupBySchoolHalfYear(dates) {
  return groupBy(dates, function (d) {
    return getSchoolHalfYear(d);
  });
}

export function groupBySchoolYear(dates) {
  return groupBy(dates, function (d) {
    return getSchoolYear(d);
  });
}

export const ReactiveDate = new Vue({
  data() {
    return {
      now: new Date(),
      currentMinute: new Date(),
      currentHour: new Date(),
      currentDay: new Date(),
    };
  },
  watch: {
    now() {
      const { now, currentMinute } = this;
      if (now.getMinutes() !== currentMinute.getMinutes()) {
        this.currentMinute = new Date(+now);
      }
    },
    currentMinute() {
      const { currentMinute, currentHour } = this;
      if (currentMinute.getHours() !== currentHour.getHours()) {
        this.currentHour = new Date(+currentMinute);
      }
    },
    currentDay() {
      const { currentHour, currentDay } = this;
      if (currentHour.getDate() !== currentDay.getDate()) {
        this.currentDay = new Date(+currentHour);
      }
    },
  },
  created() {
    this._int = setInterval(() => {
      this.now = new Date();
    }, 500);
  },
  beforeDestroy() {
    clearInterval(this._int);
  },
});

export function formattedDate(date) {
  if (!date) {
    return "";
  }
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  return date instanceof Date
    ? date.toLocaleDateString("de", options)
    : new Date(date).toLocaleDateString("de", options);
}

export function formattedMonth(date, short = false) {
  if (!date) {
    return "";
  }
  const d = date instanceof Date ? date : new Date(date);
  return new Intl.DateTimeFormat("de", {
    year: "numeric",
    month: short ? "short" : "long",
  }).format(d);
}

export function formattedWeek(date) {
  if (!date) {
    return "";
  }
  const d = date instanceof Date ? date : new Date(date);

  return `${getISOWeekYear(d)} KW${getISOWeek(d)}`;
}
