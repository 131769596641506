import gql from "graphql-tag";

export const PageInfoFragment = gql`
  fragment PageInfoFragment on PageInfo {
    hasPreviousPage
    hasNextPage
    startCursor
    endCursor
  }
`;

export const ErrorFragment = gql`
  fragment ErrorFragment on Error {
    key
    messages
    details {
      error
      value
      count
    }
  }
`;

export const CommentFragment = gql`
  fragment CommentFragment on Comment {
    id
    recordId
    recordType
    createdAt
    updatedAt
    userId
    body
    user {
      name
      email
    }
  }
`;

export const PeakIntoCommentsFragment = gql`
  fragment PeakIntoCommentsFragment on CommentConnection {
    nodes {
      ...CommentFragment
    }
  }
  ${CommentFragment}
`;

export const CurrentPassportFragment = gql`
  fragment CurrentPassportFragment on Passport {
    id
    passportNumber
    passportType
    status
    renewable
    validFrom
    validUntil
    reason
  }
`;

export const PersonSummaryFragment = gql`
  fragment PersonSummaryFragment on Person {
    id
    identifier
    firstName
    lastName
    birthday
    familyId
    is
    gender
    citizenship
    street
    streetNumber
    locality
    cityDistrictNumber
    zip
    passportsCount
    documentsCount
    applicationsAsApplicantCount
    perkUsagesCount
    currentPassport {
      ...CurrentPassportFragment
    }
  }
  ${CurrentPassportFragment}
`;

export const PersonFragment = gql`
  fragment PersonFragment on Person {
    id
    createdAt
    updatedAt
    identifier
    firstName
    lastName
    birthday
    gender
    citizenship
    phone
    handicapped
    retired
    street
    streetNumber
    locality
    cityDistrictNumber
    zip
    familyId
    is
    family {
      id
      identifier
      peopleCount
    }
    passportsCount
    digitalPassportsCount
    digitalPassportsAsHolderCount
    documentsCount
    applicationsAsApplicantCount
    perkUsagesCount
    comments(first: 1, newestFirst: true) {
      ...PeakIntoCommentsFragment
    }
    commentsCount
    currentPassport {
      ...CurrentPassportFragment
    }
  }
  ${CurrentPassportFragment}
  ${PeakIntoCommentsFragment}
`;

export const ApplicationFragment = gql`
  fragment ApplicationFragment on Application {
    id
    identifier
    items {
      id
      updatedAt
      whatType
      whatCode
      wantsVoucher
      reason
      person {
        ...PersonFragment
      }
    }
    applicant {
      ...PersonFragment
    }
    notices
    status
    createdAt
    updatedAt
    acceptedAt
    rejectedAt
    comments(first: 1, newestFirst: true) {
      ...PeakIntoCommentsFragment
    }
    commentsCount
  }
  ${PersonFragment}
  ${PeakIntoCommentsFragment}
`;

export const PassportFragment = gql`
  fragment PassportFragment on Passport {
    id
    identifier
    person {
      ...PersonSummaryFragment
    }
    passportType
    passportNumber
    status
    createdAt
    updatedAt
    issuedAt
    validFrom
    validUntil
    closedAt
    reason
    renewable
    voucher {
      id
      identifier
      voucherType
      voucherNumber
      status
      createdAt
      updatedAt
      issuedAt
      validFrom
      validUntil
      closedAt
      commentsCount
      comments(first: 1, newestFirst: true) {
        ...PeakIntoCommentsFragment
      }
    }
    comments(first: 1, newestFirst: true) {
      ...PeakIntoCommentsFragment
    }
    commentsCount
  }
  ${PersonSummaryFragment}
  ${PeakIntoCommentsFragment}
`;

export const VoucherFragment = gql`
  fragment VoucherFragment on Voucher {
    id
    identifier
    passport {
      ...PassportFragment
    }
    voucherType
    voucherNumber
    status
    createdAt
    updatedAt
    issuedAt
    validFrom
    validUntil
    closedAt
    comments(first: 1, newestFirst: true) {
      ...PeakIntoCommentsFragment
    }
    commentsCount
  }
  ${PassportFragment}
  ${PeakIntoCommentsFragment}
`;

export const DigitalPassportFragment = gql`
  fragment DigitalPassportFragment on DigitalPassport {
    id
    identifier
    person {
      ...PersonSummaryFragment
    }
    holder {
      ...PersonSummaryFragment
    }
    passport {
      ...PassportFragment
    }
    status
    createdAt
    updatedAt
    issuingStartedAt
    claimCreatedAt
    claimRedeemedAt
    claimRevokedAt
    redeemableUntil
    issueUrl
    comments(first: 1, newestFirst: true) {
      ...PeakIntoCommentsFragment
    }
    commentsCount
  }
  ${PersonSummaryFragment}
  ${PeakIntoCommentsFragment}
  ${PassportFragment}
`;

export const FamilyFragment = gql`
  fragment FamilyFragment on Family {
    id
    identifier
    peopleCount
    people {
      ...PersonFragment
    }
    createdAt
    updatedAt
    comments(first: 1, newestFirst: true) {
      ...PeakIntoCommentsFragment
    }
    commentsCount
  }
  ${PersonFragment}
  ${PeakIntoCommentsFragment}
`;

export const EmployeeFragment = gql`
  fragment EmployeeFragment on User {
    id
    name
    fullSalutation
    email
    unconfirmedEmail
    status
    disabled
    role
    rights
    createdAt
    updatedAt
    comments(first: 1, newestFirst: true) {
      ...PeakIntoCommentsFragment
    }
    commentsCount
  }
  ${PeakIntoCommentsFragment}
`;

export const FileFragment = gql`
  fragment FileFragment on File {
    key
    name
    contentType
    downloadUrl
    previewUrl
    lightboxUrl
  }
`;

export const DocumentFragment = gql`
  fragment DocumentFragment on Document {
    id
    people {
      ...PersonSummaryFragment
    }
    application {
      ...ApplicationFragment
    }
    file {
      ...FileFragment
    }
    name
    path
    createdAt
    updatedAt
    comments(first: 1, newestFirst: true) {
      ...PeakIntoCommentsFragment
    }
    commentsCount
  }
  ${PersonSummaryFragment}
  ${ApplicationFragment}
  ${FileFragment}
  ${PeakIntoCommentsFragment}
`;

export const ApplicationDetailFragment = gql`
  fragment ApplicationDetailFragment on Application {
    items {
      passport {
        ...PassportFragment
        comments(first: 1, newestFirst: true) {
          ...PeakIntoCommentsFragment
        }
        commentsCount
        voucher {
          ...VoucherFragment
          comments(first: 1, newestFirst: true) {
            ...PeakIntoCommentsFragment
          }
          commentsCount
        }
      }
    }
    applicant {
      family {
        ...FamilyFragment
      }
    }
  }
  ${PassportFragment}
  ${VoucherFragment}
  ${FamilyFragment}
  ${PeakIntoCommentsFragment}
`;

export const PerkFragment = gql`
  fragment PerkFragment on Perk {
    id
    createdAt
    updatedAt
    name
    enabled
    variants
    notes
    comments(first: 1, newestFirst: true) {
      ...PeakIntoCommentsFragment
    }
    commentsCount
  }
  ${PeakIntoCommentsFragment}
`;

export const PerkUsageFragment = gql`
  fragment PerkUsageFragment on PerkUsage {
    id
    createdAt
    updatedAt
    perk {
      id
      name
      enabled
    }
    variant
    comments(first: 1, newestFirst: true) {
      ...PeakIntoCommentsFragment
    }
    commentsCount
  }
  ${PeakIntoCommentsFragment}
`;
