<template>
  <span>
    <el-tooltip v-if="numKaPass > 0" :content="kaPass" :tabindex="-1">
      <span :class="$style.group"
        ><i class="ka-icon-karlsruhe" /> {{ numKaPass }}</span
      >
    </el-tooltip>
    <el-tooltip v-if="numKiPass > 0" :content="kiPass" :tabindex="-1">
      <span :class="$style.group"
        ><i class="ka-icon-child" /> {{ numKiPass }}</span
      >
    </el-tooltip>
    <el-tooltip v-if="numVouchers > 0" :content="vouchers" :tabindex="-1">
      <span :class="$style.group"
        ><i class="ka-icon-graduation-cap" /> {{ numVouchers }}</span
      >
    </el-tooltip>
  </span>
</template>

<script>
import {
  BI_GU,
  BI_GU_PLURAL,
  WHAT_CODES,
  WHAT_CODES_PLURAL,
} from "~/src/util/application";

export default {
  name: "ApplicationItemsSummary",
  props: {
    application: {
      type: Object,
      required: true,
    },
  },
  computed: {
    items() {
      const { application } = this;
      return application.items || [];
    },
    numKaPass() {
      const { KA_PASS } = this.nums;
      return KA_PASS || 0;
    },
    kaPass() {
      const { numKaPass } = this;
      if (numKaPass === 1) {
        return "1 " + WHAT_CODES["KA_PASS"];
      }
      return `${numKaPass} ${WHAT_CODES_PLURAL["KA_PASS"]}`;
    },
    numKiPass() {
      const { KI_PASS } = this.nums;
      return KI_PASS || 0;
    },
    kiPass() {
      const { numKiPass } = this;
      if (numKiPass === 1) {
        return "1 " + WHAT_CODES["KI_PASS"];
      }
      return `${numKiPass} ${WHAT_CODES_PLURAL["KI_PASS"]}`;
    },
    numVouchers() {
      const { BI_GU } = this.nums;
      return BI_GU || 0;
    },
    vouchers() {
      const { numVouchers } = this;
      if (numVouchers === 1) {
        return "1 " + BI_GU;
      }
      return `${numVouchers} ${BI_GU_PLURAL}`;
    },
    nums() {
      const { items } = this;
      const codeCounts = {};
      let voucherCount = 0;
      items.forEach(function (item) {
        if (!(item.whatCode in codeCounts)) {
          codeCounts[item.whatCode] = 0;
        }
        codeCounts[item.whatCode] += 1;
        if (item.wantsVoucher) {
          voucherCount += 1;
        }
      });
      return {
        ...codeCounts,
        BI_GU: voucherCount,
      };
    },
  },
};
</script>
<style module lang="module.css">
.group + .group {
  margin-left: 6px;
}
</style>
