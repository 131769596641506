<script>
import { formattedDate, formattedMonth, formattedWeek } from "~/src/util/date";

export default {
  name: "LocaleDate",
  functional: true,
  props: {
    date: {
      type: [String, Date],
      default: "",
    },
    onlyMonth: {
      type: Boolean,
      default: false,
    },
    onlyWeek: {
      type: Boolean,
      default: false,
    },
  },
  render(createElement, context) {
    const { date, onlyMonth, onlyWeek } = context.props;

    if (onlyMonth) {
      return createElement("span", formattedMonth(date));
    }
    if (onlyWeek) {
      return createElement("span", formattedWeek(date));
    }
    return createElement("span", formattedDate(date));
  },
};
</script>