<template>
  <span v-text="reason" />
</template>

<script>
import { REASONS_HUMAN } from "~/src/data/passport-reasons";

export default {
  name: "PassportReason",
  props: {
    passport: {
      type: Object,
      required: true,
    },
  },
  computed: {
    reason() {
      return REASONS_HUMAN[this.passport.reason];
    },
  },
};
</script>
