// do not edit, this file is generated
export const REASONS = {"Asylbewerberleistungsgesetz":1,"Berechnung":2,"BUT":3,"Familie5KinderPlus":4,"KindMitBehinderung":5,"Kinderzuschlag":6,"NAE6MonateBerechnung":7,"NAE6MonateSozialleistung":8,"Pflegekind":9,"SGB_II":10,"SGB_VIII":11,"SGB_XII":12,"SGB_XII_Taschengeld":13,"UMF":14,"UMF_UE18":15,"Wohngeld":16,"Sontiges":17,"JungeErwachseneMitBehinderung":18}
export const REASONS_HUMAN = {"Asylbewerberleistungsgesetz":"Asylbewerberleistungsgesetz","Berechnung":"Berechnung","BUT":"BUT","Familie5KinderPlus":"Familie 5 Kinder+","KindMitBehinderung":"Kind mit Behinderung","Kinderzuschlag":"Kinderzuschlag","NAE6MonateBerechnung":"NAE 6 Monate Berechnung","NAE6MonateSozialleistung":"NAE 6 Monate Sozialleistung","Pflegekind":"Pflegekind","SGB_II":"SGB II","SGB_VIII":"SGB VIII","SGB_XII":"SGB XII","SGB_XII_Taschengeld":"SGB XII (Taschengeld)","UMF":"UMF","UMF_UE18":"UMF Ü18","Wohngeld":"Wohngeld","Sontiges":"Sontiges","JungeErwachseneMitBehinderung":"18-20 Jahre laut Beschluss"}
export const REASONS_INT_HUMAN = {"1":"Asylbewerberleistungsgesetz","2":"Berechnung","3":"BUT","4":"Familie 5 Kinder+","5":"Kind mit Behinderung","6":"Kinderzuschlag","7":"NAE 6 Monate Berechnung","8":"NAE 6 Monate Sozialleistung","9":"Pflegekind","10":"SGB II","11":"SGB VIII","12":"SGB XII","13":"SGB XII (Taschengeld)","14":"UMF","15":"UMF Ü18","16":"Wohngeld","17":"Sontiges","18":"18-20 Jahre laut Beschluss"}
export const KA_PASS_REASONS = ["Asylbewerberleistungsgesetz","Berechnung","Kinderzuschlag","NAE6MonateBerechnung","NAE6MonateSozialleistung","SGB_II","SGB_VIII","SGB_XII","SGB_XII_Taschengeld","UMF_UE18","Wohngeld"]
export const KA_PASS_REASONS_HUMAN = {"Asylbewerberleistungsgesetz":"Asylbewerberleistungsgesetz","Berechnung":"Berechnung","Kinderzuschlag":"Kinderzuschlag","NAE6MonateBerechnung":"NAE 6 Monate Berechnung","NAE6MonateSozialleistung":"NAE 6 Monate Sozialleistung","SGB_II":"SGB II","SGB_VIII":"SGB VIII","SGB_XII":"SGB XII","SGB_XII_Taschengeld":"SGB XII (Taschengeld)","UMF_UE18":"UMF Ü18","Wohngeld":"Wohngeld"}
export const KI_PASS_REASONS = ["Asylbewerberleistungsgesetz","Berechnung","BUT","Familie5KinderPlus","KindMitBehinderung","Kinderzuschlag","NAE6MonateBerechnung","NAE6MonateSozialleistung","Pflegekind","SGB_II","SGB_VIII","SGB_XII","SGB_XII_Taschengeld","UMF","Wohngeld","JungeErwachseneMitBehinderung"]
export const KI_PASS_REASONS_HUMAN = {"Asylbewerberleistungsgesetz":"Asylbewerberleistungsgesetz","Berechnung":"Berechnung","BUT":"BUT","Familie5KinderPlus":"Familie 5 Kinder+","KindMitBehinderung":"Kind mit Behinderung","Kinderzuschlag":"Kinderzuschlag","NAE6MonateBerechnung":"NAE 6 Monate Berechnung","NAE6MonateSozialleistung":"NAE 6 Monate Sozialleistung","Pflegekind":"Pflegekind","SGB_II":"SGB II","SGB_VIII":"SGB VIII","SGB_XII":"SGB XII","SGB_XII_Taschengeld":"SGB XII (Taschengeld)","UMF":"UMF","Wohngeld":"Wohngeld","JungeErwachseneMitBehinderung":"18-20 Jahre laut Beschluss"}
export const KA_PASS_BI_GU = {"Asylbewerberleistungsgesetz":3,"Berechnung":3,"BUT":0,"Familie5KinderPlus":0,"KindMitBehinderung":0,"Kinderzuschlag":3,"NAE6MonateBerechnung":4,"NAE6MonateSozialleistung":4,"Pflegekind":0,"SGB_II":3,"SGB_VIII":0,"SGB_XII":3,"SGB_XII_Taschengeld":3,"UMF":0,"UMF_UE18":0,"Wohngeld":3}
export const KA_PASS_BI_GU_HUMAN = {"Asylbewerberleistungsgesetz":"Anspruch ab 60 Jahren","Berechnung":"Anspruch ab 60 Jahren","BUT":"kein Anspruch","Familie5KinderPlus":"kein Anspruch","KindMitBehinderung":"kein Anspruch","Kinderzuschlag":"Anspruch ab 60 Jahren","NAE6MonateBerechnung":"Anspruch ab 60 Jahren (anteilig)","NAE6MonateSozialleistung":"Anspruch ab 60 Jahren (anteilig)","Pflegekind":"kein Anspruch","SGB_II":"Anspruch ab 60 Jahren","SGB_VIII":"kein Anspruch","SGB_XII":"Anspruch ab 60 Jahren","SGB_XII_Taschengeld":"Anspruch ab 60 Jahren","UMF":"kein Anspruch","UMF_UE18":"kein Anspruch","Wohngeld":"Anspruch ab 60 Jahren"}
export const KI_PASS_BI_GU = {"Asylbewerberleistungsgesetz":0,"Berechnung":2,"BUT":0,"Familie5KinderPlus":2,"KindMitBehinderung":1,"Kinderzuschlag":0,"NAE6MonateBerechnung":5,"NAE6MonateSozialleistung":5,"Pflegekind":1,"SGB_II":0,"SGB_VIII":0,"SGB_XII":0,"SGB_XII_Taschengeld":0,"UMF":0,"UMF_UE18":0,"Wohngeld":0,"JungeErwachseneMitBehinderung":1}
export const KI_PASS_BI_GU_HUMAN = {"Asylbewerberleistungsgesetz":"kein Anspruch","Berechnung":"Anspruch bei Ablehnung BUT","BUT":"kein Anspruch","Familie5KinderPlus":"Anspruch bei Ablehnung BUT","KindMitBehinderung":"Anspruch","Kinderzuschlag":"kein Anspruch","NAE6MonateBerechnung":"Anspruch bei Ablehnung BUT (anteilig)","NAE6MonateSozialleistung":"Anspruch bei Ablehnung BUT (anteilig)","Pflegekind":"Anspruch","SGB_II":"kein Anspruch","SGB_VIII":"kein Anspruch","SGB_XII":"kein Anspruch","SGB_XII_Taschengeld":"kein Anspruch","UMF":"kein Anspruch","UMF_UE18":"kein Anspruch","Wohngeld":"kein Anspruch","JungeErwachseneMitBehinderung":"Anspruch"}
export const BI_GU_LEGEND = ["kein Anspruch","Anspruch","Anspruch bei Ablehnung BUT","Anspruch ab 60 Jahren","Anspruch ab 60 Jahren (anteilig)","Anspruch bei Ablehnung BUT (anteilig)"]
export const DOCUMENTS = ["SGB II","Wohngeld","Kinderzuschlag","SGB XII","SGB VIII","AsylbLG","Pflegebescheinigung","Schwerbehindertenausweis","gem. GR-Beschluss","Berechnung"]
export const REASON_TO_DOCUMENTS = {"Asylbewerberleistungsgesetz":["AsylbLG"],"Berechnung":["Berechnung"],"BUT":[],"Familie5KinderPlus":[],"KindMitBehinderung":["Schwerbehindertenausweis"],"Kinderzuschlag":["Kinderzuschlag"],"NAE6MonateBerechnung":["Berechnung"],"NAE6MonateSozialleistung":[],"Pflegekind":["Pflegebescheinigung"],"SGB_II":["SGB II"],"SGB_VIII":["SGB VIII"],"SGB_XII":["SGB XII"],"SGB_XII_Taschengeld":["SGB XII"],"UMF":[],"UMF_UE18":[],"Wohngeld":["Wohngeld"],"JungeErwachseneMitBehinderung":["Schwerbehindertenausweis"]}
export const REASONS_DEFAULT_MONTHS = {"Asylbewerberleistungsgesetz":12,"Berechnung":12,"BUT":12,"Familie5KinderPlus":12,"KindMitBehinderung":12,"Kinderzuschlag":12,"NAE6MonateBerechnung":6,"NAE6MonateSozialleistung":6,"Pflegekind":12,"SGB_II":12,"SGB_VIII":12,"SGB_XII":12,"SGB_XII_Taschengeld":12,"UMF":12,"UMF_UE18":12,"Wohngeld":12,"Sontiges":12,"JungeErwachseneMitBehinderung":12}
