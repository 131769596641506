<template>
  <el-tag :type="tagType" :size="size">
    <i :class="icon" />
    <span v-text="status" />
  </el-tag>
</template>

<script>
import {
  getPassportStatusIcon,
  getPassportTagType,
  PASSPORT_STATUS_HUMAN,
} from "~/src/util/passport";

export default {
  name: "PassportStatus",
  props: {
    passport: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: "mini",
    },
  },
  computed: {
    icon() {
      return getPassportStatusIcon(this.passport);
    },
    tagType() {
      return getPassportTagType(this.passport);
    },
    status() {
      return PASSPORT_STATUS_HUMAN[this.passport.status];
    },
  },
};
</script>
