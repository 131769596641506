export default {
  attachFiles: "Dateien anfügen",
  bold: "Fett",
  bullets: "Liste",
  byte: "Byte",
  bytes: "Bytes",
  captionPlaceholder: "Eine Beschreibung hinzufügen…",
  code: "Code",
  heading1: "Überschrift",
  indent: "Einrückung vergrößern",
  italic: "Kursiv",
  link: "Link",
  numbers: "Liste (nummeriert)",
  outdent: "Einrückung verkleinern",
  quote: "Zitat",
  redo: "Wiederholen",
  remove: "Entfernen",
  strike: "Durchgestrichen",
  undo: "Undo",
  unlink: "Link entfernen",
  url: "URL",
  urlPlaceholder: "URL eingeben…",
  GB: "GB",
  KB: "KB",
  MB: "MB",
  PB: "PB",
  TB: "TB",
};
